import React, { useReducer, useEffect } from "react";
import { validate } from "../../utils/validators";

// Styles
import styles from "./Input.module.scss";

// En fonction de l'etat de l'objet html (change = ecriture / touch = utilisateur change de champ)
const inputReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE":
            return {
                // coppier tous les valeurs d'avant et ajoute le nouveau événement (une lettre par exemple)
                ...state,
                value: action.val,
                // changer la validation en fonction des validateurs de l'objet html
                isValid: validate(action.val, action.validators),
            };
        case "TOUCH": {
            return {
                ...state,
                isTouched: true,
            };
        }
        default:
            return state;
    }
};

// Component
const Input = (props) => {

    /*
    ##### IMPORTANT #####
    To bind the input component to the formstate, always use the same formState object key in the id and name of the input component!
    */

    // état initial du composant
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || "",
        isTouched: false,
        isValid: props.initialValid || false,
    });

    // déconstruction de props et inputState
    const { id, onInput } = props;
    const { value, isValid } = inputState;

    // id du composant, la valeur capturée et sa validation de donnés
    useEffect(() => {
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    // Fonction pour capturer le changement de l'état du composant (écriture)
    const changeHandler = (event) => {
        dispatch({
            type: "CHANGE",
            val: event.target.value,
            validators: props.validators,
        });

        // Add onCount possibility if you want to count any change event.target.value
        if (props.onCountFctn) props.onCountFctn(event);
        if (props.onMulitpleOptions) props.onMulitpleOptions(event);
    };

    // Fonction pour capturer le changement de l'état du composant (changer de champ)
    const touchHandler = () => {
        dispatch({
            type: "TOUCH",
        });
        if (props.onBlurAltFctn) props.onBlurAltFctn();
    };

    let input, label;

    if (props.label) {
        label = (
            <label className={styles.label} htmlFor={props.id}>
                {props.label} {props.asterisque && (
                    <span style={{color: "red"}}>*</span>
                )}
            </label>
        );
    }

    if (props.typeOfInput) {
        switch (props.typeOfInput) {
            case "input":
                input = (
                    <div className={`${styles.container} ${props.styles}`}>
                        {label}
                        <input
                            id={props.id}
                            className={`${props.boxStyling || styles.box} ${
                                !inputState.isValid && inputState.isTouched && styles.invalid
                            }`}
                            name={props.name}
                            type={props.type}
                            pattern={props.pattern}
                            maxLength={props.maxLength}
                            minLength={props.minLength}
                            placeholder={props.placeholder}
                            autoComplete={props.autocomplete}
                            value={inputState.value}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            onKeyPress={props.keyPress}
                            disabled={props.disabled}
                        ></input>
                        {!inputState.isValid && inputState.isTouched && (
                            <p className={styles.input_error}>{props.errorText}</p>
                        )}
                    </div>
                );
                break;

            case "select":
                input = (
                    <div className={`${styles.container} ${props.styles}`}>
                        {label}
                        <select
                            id={props.id}
                            className={`${styles.box} ${props.selectInput} ${
                                !inputState.isValid && inputState.isTouched && styles.invalid
                            }`}
                            name={props.name}
                            type={props.type}
                            maxLength={props.maxLength}
                            minLength={props.minLength}
                            placeholder={props.placeholder}
                            autoComplete={props.autocomplete}
                            multiple={props.multiple}
                            value={inputState.value}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            disabled={props.disabled}
                        >
                            {props.content.map((prop) => {
                                if (inputState.value === prop.label)
                                    return (
                                        <option
                                            value={prop.label}
                                            key={prop.label}
                                            defaultValue={prop.label}
                                        >
                                            {prop.default}
                                        </option>
                                    );
                                else
                                    return (
                                        <option value={prop.label} key={prop.label}>
                                            {prop.default}
                                        </option>
                                    );
                            })}
                        </select>
                        {!inputState.isValid && inputState.isTouched && (
                            <p className={styles.input_error}>{props.errorText}</p>
                        )}
                    </div>
                );
                break;

            case "textArea":
                input = (
                    <div className={`${styles.container} ${props.styles}`}>
                        {label}
                        <textarea
                            id={props.id}
                            className={`${props.boxStyles || styles.box} ${
                                !inputState.isValid && inputState.isTouched && styles.invalid
                            }`}
                            name={props.name}
                            type={props.type}
                            rows={props.rows}
                            cols={props.cols}
                            maxLength={props.maxLength}
                            minLength={props.minLength}
                            placeholder={props.placeholder}
                            autoComplete={props.autocomplete}
                            value={inputState.value}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            disabled={props.disabled}
                        />
                        {!inputState.isValid && inputState.isTouched && (
                            <p className={styles.input_error}>{props.errorText}</p>
                        )}
                    </div>
                );
                break;

            default:
                break;
        }
    }

    return input;
};

export default Input;
