/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import styles from "./DocumentsListDisplay.module.scss";

import { isRequired, isBlank } from "../../../utils/validators";

// Components
import Input from "../../../components/Input/Input";
import { useForm } from "../../../hooks/form-hook";
import OffersTable from "../../OffersTable/OffersTable";
import DocLinkBanner from "../../DocLinkBanner/DocLinkBanner";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';


const DocumentsListDisplay = ({id, content}) => {

  const [date, setDate] = useState('');

  const [validOffers, setValidOffers] = useState(content);

  const contratModalities = [
    { default: "- -", label: "" },
    { default: "Alternance", label: "Alternance" },
    { default: "CDI / CDD", label: "CDI / CDD" },
    { default: "Stage", label: "Stage" },
    { default: "Stage Alterné", label: "Stage Alterné" },
    { default: "Service Civique", label: "Service Civique" },
  ];

  const campusModalities = [
    { default: "- -", label: "" },
    { default: "Campus de Paris", label: "74" },
    { default: "Campus de Lyon", label: "75" },
    { default: "Campus de Rennes", label: "84" },
    { default: "Campus de Nantes", label: "80" },
    { default: "Campus d'Aix/Marseille", label: "78" },
    { default: "Campus de Strasbourg", label: "85" },
    { default: "Campus de Montpellier", label: "82" },
    { default: "Campus de Lille", label: "79" },
    { default: "Campus de Bordeaux", label: "77" },
    { default: "Campus de Toulouse", label: "83" },
    { default: "Campus de Paris Est", label: "86" },
  ];


  const [formState, inputHandler, setFormState] = useForm(
  {
    type_of_contract: {
      value: "",
      isValid: true,
    },
    campus: {
      value: "",
      isValid: true,
    },
    zipCode: {
      value: "",
      isValid: true,
    },
  });


  const currentDate = new Date();
// filtrer les offres en fonction de leur date maximale de visibilité

  const archivedOffers = content.filter((offer) => {
    if (offer.archive) {
      return offer.archive;
    } else return false;
  });

  const [showArchivedOffers, setShowArchivedOffers] = useState(false);

  const toggleArchivesOffres = () => {
    setShowArchivedOffers(!showArchivedOffers)
  };

  const handleOfferClick = (offer) => {
    if (offer.link) {
      window.open(offer.link, '_blank'); // Ouvre le lien dans un nouvel onglet
    }
  };

  const filterAction = () => {
    let filters = {};

    if (formState.inputs['campus'].value != "") filters['place_id'] = formState.inputs['campus'].value;
    if (formState.inputs['zipCode'].value != "") filters['adr_zip'] = formState.inputs['zipCode'].value;
    if (formState.inputs['type_of_contract'].value != "") filters['contrat'] = formState.inputs['type_of_contract'].value;
    filters['date'] = date;

    
    const filtredOffers = validOffers.filter(function (offer) {
      const zipCodeMatch = (filters['adr_zip'] && offer.adr_zip) ? offer.adr_zip.startsWith(filters['adr_zip']) : true;
      const campusMatch = (filters['place_id'] && offer.place_id) ? offer.place_id == filters['place_id'] : true;
      const contratMatch = (filters['contrat'] && offer.contrat) ? offer.contrat == filters['contrat'] : true;
      const dateMatch = (filters['date'] && offer.date) ? offer.date < new Date(filters['date']).toISOString().split('T')[0] : true;

      return zipCodeMatch && campusMatch && contratMatch && dateMatch;
    })
    setValidOffers(filtredOffers);
  }

  useEffect(() => {
    let validOffersToDisplay = content.filter((offer) => {
      if (offer.archive) return false;
      return true;
    });
    setValidOffers(validOffersToDisplay);
  }, [content]);


  const deleteFilterAction = () => {
    setFormState(
      {
          campus: {
              value: "",
              isValid: true,
          },
          zipCode: {
              value: "",
              isValid: true,
          },
          type_of_contract: {
              value: "",
              isValid: true,    
          },
      },
      true,
  );
  setValidOffers(content.filter((offer) => {
    if (offer.archive) return false;
    return true
  }));
  setDate('');
}

let jsx;

if (id === "mygema_offre") {
  jsx = (
    <>
      <div className={styles.offerFilterContainer}>
        <Input
          id="type_of_contract"
          name="type_of_contract"
          type="text"
          typeOfInput="select"
          content={contratModalities}
          label="Type de contrat"
          selectInput={styles.selectInput}
          // disabled={true}
          styles={styles.inputFilter}
          validators={[isBlank()]}
          errorText="Veuillez choisir un type"
          initialValue={formState.inputs["type_of_contract"].value}
          initialValid={formState.inputs["type_of_contract"].isValid}
          onInput={inputHandler}
        />

        <div className={styles.datePicker}>
          <label htmlFor="date" className={styles.label}>
            Date max de l'offre
          </label>
          <DatePicker
            id="dateMax"
            value={date}
            validators={[isBlank()]}
            locale="fr"
            className={styles.datePickerInput}
            dateFormat="dd/MM/yyyy"
            selected={date}
            onChange={(date) => setDate(date)}
          />
        </div>
        <Input
          id="campus"
          name="campus"
          type="text"
          typeOfInput="select"
          content={campusModalities}
          label="À proximité du"
          selectInput={styles.selectInput}
          // disabled={true}
          styles={styles.inputFilter}
          validators={[isBlank()]}
          errorText="Veuillez choisir un campus"
          initialValue={formState.inputs["campus"].value}
          initialValid={formState.inputs["campus"].isValid}
          onInput={inputHandler}
        />
        <Input
          id="zipCode"
          name="zipCode"
          type="text"
          typeOfInput="input"
          label="Code Postal"
          // disabled={true}
          styles={styles.inputFilter}
          validators={[isBlank()]}
          errorText="Veuillez choisir un code postal valide."
          initialValue={formState.inputs["zipCode"].value}
          initialValid={formState.inputs["zipCode"].isValid}
          onInput={inputHandler}
        />
      </div>
      <div className={styles.filterButtons}>
        <div className={styles.refreshButton} onClick={() => {filterAction()}}>
          <div className={styles.refreshIcon}>
            <RefreshIcon fontSize="large"/>
          </div>
        </div>
        <div className={styles.deleteFilterButton} onClick={() => {deleteFilterAction()}}>
          <div className={styles.deleteFilterIcon}>
            <ClearIcon fontSize="large" />
          </div>
        </div>
      </div>
      {/*display valid offers => archive == false (offre not archived)*/}
      {validOffers && (
        <OffersTable offers={validOffers} onOfferClick={handleOfferClick} />
      )}
      {/* display archived offers */}
      <p className={styles.archivetitle}>
        OFFRES DU PÔLE CARRIÈRES ARCHIVÉES
        <button onClick={toggleArchivesOffres}>
          {showArchivedOffers ? "-" : "+"}
        </button>
      </p>
      {showArchivedOffers && (
        <OffersTable offers={archivedOffers} onOfferClick={handleOfferClick} />
      )}
    </>
  );
} else {
  jsx = (
    <>
      {/* general behavior for other tabs */}
      {content.map(
        ({ status, type, icon, link, title, iconColor, hashtag }, index) => (
          <DocLinkBanner
            key={index}
            config={id}
            docType={type}
            status={status}
            title={title}
            icon={icon}
            iconColor={iconColor}
            link={link}
            hashtag={hashtag}
          />
        )
      )}
    </>
  );
}

return jsx;

};
  export default DocumentsListDisplay;