/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { useForm } from "../../../../../hooks/form-hook";
import { MinLength, isRequired, isText, isAlphanumeric, isNumber, isCvec } from "../../../../../utils/validators";
import { ToastContainer, toast } from 'react-toastify';
import { AcademicLabelsForLevels } from "../../../../../utils/dictionaries";

// Components
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner"
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import FilePicker from "../../../../FilePicker/FilePicker";

// Styles
import styles from "./CvecDetails.module.scss";

const CvecDetails = ({ student, setStudent }) => {
  // Authentication context
  const auth = useContext(AuthContext);

  // Http request hook
  const { isLoading, error, okHttp, resetHttpRequest, sendRequest } =
    useHttpRequest();

  const [commitments] = useState(student.commitmentsForCvec);

  const [commitmentN, setCommitmentN] = useState(false);

  const [commitmentN1, setCommitmentN1] = useState(false);

  const [isReady, setIsReady] = useState(false);

  // FormState Hook
  const [formState, inputHandler, setFormState] = useForm();

  const submit = async () => {
    
    if (
      (commitmentN.credit_identifier != formState.inputs.cvec_n.value &&
      !formState.inputs.file_cvec_n.value) 
      ||
      (commitmentN1 &&
      (commitmentN1.credit_identifier != formState.inputs.cvec_n_1.value &&
        !formState.inputs.file_cvec_n_1.value))
    ) {
      toast.error(
        `${"Merci de fournir votre attestation de CVEC correspondant à votre numéro d'attestation."}`,
        {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        }
      );
      return;
    } else if (
      (formState.inputs.file_cvec_n.value &&
        (commitmentN.credit_identifier == formState.inputs.cvec_n.value ||
          (formState.inputs.cvec_n.value == "" || !formState.inputs.cvec_n.isValid))) 
        ||
      (formState.inputs.file_cvec_n_1.value &&
        (commitmentN1.credit_identifier == formState.inputs.cvec_n_1.value ||
          (formState.inputs.cvec_n_1.value == "" || !formState.inputs.cvec_n_1.isValid)))
    ) {
      toast.error(
        `${"Merci de fournir votre numéro de CVEC correspondant à votre attestation."}`,
        {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        }
      );
      return;
    }

    const formData = new FormData();

    // File & input Data
    if (formState.inputs.file_cvec_n.value) {
        formData.append("cvec_n", formState.inputs.cvec_n.value);
        formData.append('year', commitmentN.caption);  // Indique que ce fichier est pour 2023-2024
        formData.append("file", formState.inputs.file_cvec_n.value);
    }
    if (formState.inputs.file_cvec_n_1.value) {
        formData.append("cvec_n_1", formState.inputs.cvec_n_1.value);
        formData.append('year', commitmentN1.caption);  // Indique que ce fichier est pour 2024-2025
        formData.append("file", formState.inputs.file_cvec_n_1.value);
    }

    const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
    try {
        await sendRequest(url, "POST", formData, {
        Authorization: "Bearer " + auth.token,
      });

      setStudent((prevData) => {
        const { cvec_n, cvec_n_1 } = formData;
        return { ...prevData, cvec_n, cvec_n_1 };
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (commitments) {
      commitments.forEach((commitment) => {
        if (student.currentSchoolYear === commitment.caption.substring(0, 9)) {
          setCommitmentN(commitment);
        } else if (student.nextSchoolYear === commitment.caption.substring(0, 9)) {
          setCommitmentN1(commitment);
        }
      });
    }
  }, [commitments]);

  useEffect(() => {
    if (commitmentN || commitmentN1) {
      setFormState(
        {
          cvec_n: {
            value: commitmentN.credit_identifier || "",
            isValid: true,
          },
          cvec_n_1: {
            value: commitmentN1.credit_identifier || "",
            isValid: true,
          },
          file_cvec_n: {
            value: "",
            isValid: false,
          },
          file_cvec_n_1: {
            value: "",
            isValid: false,
          },
        },
        false
      );
      setIsReady(true);
    }
  }, [commitmentN, commitmentN1]);


  useEffect(() => {
    if (okHttp || error) setTimeout(resetHttpRequest, 4000);
  }, [okHttp, error]);

  return (
    <div className={styles.civilStateInput}>
      {okHttp && !isLoading && (
        <p style={{ color: "#00b61d", textAlign: "center" }}>
          Votre demande a bien été prise en compte. Merci.
        </p>
      )}
      {error && !isLoading && (
        <p style={{ color: "#e40000", textAlign: "center" }}>
          Une erreur a été détectée, s'il vous plait, veuillez réessayer
          ultèrieurement.
        </p>
      )}
      {commitmentN && isReady && (
        <div>
          <div className={styles.commitmentP}>
            {" "}
            {AcademicLabelsForLevels[commitmentN.property_1] ||
              commitmentN.property_1}{" "}
            ({commitmentN.caption})
          </div>
          <div className={styles.commitmentBlock}>
            <Input
              id="cvec_n"
              name="cvec_n"
              type="text"
              typeOfInput="input"
              label="Numéro d'attestation CVEC"
              validators={[MinLength(4), isCvec()]}
              errorText="Format LLLCLLLLLLCC (L = Lettre, C = chiffre) uniquement"
              initialValue={formState.inputs.cvec_n.value}
              initialValid={formState.inputs.cvec_n.isValid}
              onInput={inputHandler}
              styles={styles.input}
            />
            <div className={styles.documentsDiv}>
              <p className={styles.cvecP}>Attestation de CVEC</p>
              <div className={styles.divCvec}>
                {student.attestation_cvec_n && (
                  <FilePicker
                    mimeTypes=".jpg, .jpeg, .pdf"
                    inputHandler={inputHandler}
                    formState={formState}
                    formStateFileKey={"file_cvec_n"}
                    labelProps={"Attestation CVEC transmise."}
                    completed={true}
                  />
                )}
                <div className={styles.filePickerDiv}>
                  <FilePicker
                    mimeTypes=".jpg, .jpeg, .pdf"
                    inputHandler={inputHandler}
                    formStateFileKey={"file_cvec_n"}
                    formState={formState}
                    labelProps={
                      "Cliquez ici pour nous transmettre ou changer votre attestation de CVEC *"
                    }
                  />
                </div>
              </div>
            </div>
            <ActionBtn
              id="subitCivilState"
              btnType="contained"
              btnStyle={styles.submitBtn}
              activeBtnStyle={styles.btn_active}
              btnText="ENREGISTRER"
              textStyle={styles.btn_text}
              onClick={submit}
            />
          </div>
        </div>
      )}
      {commitmentN1 && isReady && (
        <div>
          <div className={styles.commitmentP}>
            {AcademicLabelsForLevels[commitmentN1.property_1] ||
              commitmentN1.property_1}{" "}
            ({commitmentN1.caption})
          </div>
          <div className={styles.commitmentBlock}>
            <Input
              id="cvec_n_1"
              name="cvec_n_1"
              type="text"
              typeOfInput="input"
              label="Numéro d'attestation CVEC"
              validators={[MinLength(4), isCvec()]}
              errorText="Format LLLCLLLLLLCC (L = Lettre, C = chiffre) uniquement"
              initialValue={formState.inputs.cvec_n_1.value}
              initialValid={formState.inputs.cvec_n_1.isValid}
              onInput={inputHandler}
              styles={styles.input}
            />
            <div className={styles.documentsDiv}>
              <p className={styles.cvecP}>Attestation de CVEC</p>
              <div className={styles.divCvec}>
                {student.attestation_cvec_n_1 && (
                  <FilePicker
                    mimeTypes=".jpg, .jpeg, .pdf"
                    inputHandler={inputHandler}
                    formState={formState}
                    formStateFileKey={"file_cvec_n_1"}
                    labelProps={"Attestation CVEC transmise."}
                    completed={true}
                  />
                )}
                <div className={styles.filePickerDiv}>
                  <FilePicker
                    mimeTypes=".jpg, .jpeg, .pdf"
                    inputHandler={inputHandler}
                    formState={formState}
                    formStateFileKey={"file_cvec_n_1"}
                    labelProps={
                      "Cliquez ici pour nous transmettre ou changer votre attestation de CVEC *"
                    }
                  />
                </div>
              </div>
            </div>
            <ActionBtn
              id="subitCivilState"
              btnType="contained"
              btnStyle={styles.submitBtn}
              activeBtnStyle={styles.btn_active}
              btnText="ENREGISTRER"
              textStyle={styles.btn_text}
              onClick={submit}
            />
          </div>
        </div>
      )}
      {isLoading && <InLineLoadingSpinner />}
      <ToastContainer />
    </div>
  );
};

export default CvecDetails;
