import React, {useContext, useEffect, useState} from "react";
import { useForm } from "../../../../../hooks/form-hook";
import { isRequired } from "../../../../../utils/validators";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";

// Components
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner"

// Styles
import styles from "./MyProfile.module.scss";

const MyProfil = ({ student, setStudent }) => {
    
    // Authentication context
    const auth = useContext(AuthContext);

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();
    const [validateSubmit, setValidateSubmit] = useState(false);

    // FormState Hook
    const [formState, inputHandler] = useForm(
        {
            place_id: {
                value: student.place_id,
                isValid: student.place_id ? true : false,
            },
            n_last: {
                value: student.n_last,
                isValid: student.n_last ? true : false,
            },
            n_first: {
                value: student.n_first,
                isValid: student.n_first ? true : false,
            },
            int_1: {
                value: student.int_1 || "",
                isValid: student.int_1 ? true : false,
            },
        },
        false
    );

    const selectOptions = [
        { default: "", label: ""},
        { default: "Oui", label: "2" },
        { default: "Non", label: "1" },
    ];

    const submit = async () => {
        const data = {
            int_1: formState.inputs.int_1.value,
        };

        try {
            const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
            const response = await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
            });

            setStudent((prevData) => {
                const { int_1 } = data;
                return { ...prevData, int_1 };
            });
            setValidateSubmit(true);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000)
    }, [okHttp, error]);

    return (
        <>
            {isLoading && <InLineLoadingSpinner />}
            {okHttp && !isLoading && (
                <div className={styles.myProfilContainer}>
                    <p style={{ color: "#00b61d" }}>
                        Votre demande a bien été prise en compte. Merci.
                    </p>
                </div>
            )}
            {error && !isLoading && (
                <div className={styles.myProfilContainer}>
                    <p style={{ color: "#e40000" }}>
                        Une erreur a été détectée, s'il vous plait, veuillez réessayer
                        ultèrieurement.
                    </p>
                </div>
            )}
            <div className={styles.myProfilContainer}>
                <div className={styles.profilInput}>
                    <Input
                        onInput={inputHandler}
                        label="Etablissement"
                        type="text"
                        name="place_id"
                        id="place_id"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.place_id.value}
                        initialValid={formState.inputs.place_id.isValid}
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Nom"
                        type="text"
                        name="n_last"
                        id="n_last"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.n_last.value}
                        initialValid={formState.inputs.n_last.isValid}
                        typeOfInput="input"
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Prénom"
                        type="text"
                        name="n_first"
                        id="n_first"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.n_first.value}
                        initialValid={formState.inputs.n_first.isValid}
                        typeOfInput="input"
                        styles={styles.input}
                        disabled={true}
                    />
                    <ActionBtn
                    id="subitCivilState"
                    btnType="contained"
                    btnStyle={styles.submitBtn}
                    activeBtnStyle={styles.btn_active}
                    btnText="ENREGISTRER"
                    textStyle={styles.btn_text}
                    onClick={submit}
                />
                </div>
                <div className={styles.profilInput}>
                    <Input
                        onInput={inputHandler}
                        label="Êtes vous en situation de handicap ?"
                        type="text"
                        name="int_1"
                        id="int_1"
                        typeOfInput="select"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.int_1.value}
                        initialValid={formState.inputs.int_1.isValid}
                        styles={styles.input}
                        content={selectOptions}
                        disabled={(formState.inputs.int_1.value != '' && validateSubmit) ? true : false}
                    />
                </div>
            </div>
        </>
    );
};

export default MyProfil;
