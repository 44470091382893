import React from "react";
import { Link } from "react-router-dom";
import HardLink from "../Buttons/HardLink/HardLink";

// Styles
import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className={styles.container}>
            <div className={styles.links_wrapper}>
                <Link to="/general-terms-of-use" className={styles.link}>
                    Conditions d’utilisation et protection des données
                </Link>
                <HardLink 
                    linkTo="https://intranet.groupe-gema.com/img/ESI/RGPD_et_AutoProctor.mp4"
                    title="AutoProctor et RGPD"
                />
            </div>
            <div className={styles.copyright_container}>
                <p className={styles.text}>Copyright Groupe GEMA 2022</p>
            </div>
        </footer>
    );
};

export default Footer;
