/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "../../../../hooks/form-hook";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../context/auth-context";
import { TeacherContext } from "../../../../context/teacher-context";
import { isRequired } from "../../../../utils/validators";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

// Components
import ActionBtn from "../../../Buttons/ActionBtn/ActionBtn";
import ModalHeader from "../../../ModalHeader/ModalHeader";
import Input from "../../../Input/Input";
import Avatar1 from "../../../../assets/images/avatar.svg";
import StudentEval from "../EvaluationTabs/StudentEval/StudentEval";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../HttpMessagePrompt/HttpMessagePrompt";
import styles from "./GenerateEvaluation.module.scss";
import CheckBox from "../../../CheckBox/CheckBox";

registerLocale("fr", fr);

const GenerateEvaluation = (props) => {
    // Authentication context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(TeacherContext);

    // Backend Request Hook
    const { isLoading, error, okHttp, sendRequest } = useHttpRequest();

    const [submitErr, setSubmitErr] = useState(false);

    const [isReady, setIsReady] = useState(true);

    const [report] = useState(props.report);

    const [onPost, setOnPost] = useState(false);

    const [onDelete, setOnDelete] = useState(false);

    const [note] = useState(props.notes);

    const [isChecked, setIsChecked] = useState(false);

    const [bddError, setBddError] = useState(false);

    // Students
    const [students, setStudents] = useState();

    // Date state
    let noteDate;
    if (props.action === "update") noteDate = new Date(props.notes[0].date);
    else noteDate = new Date();

    const [date, setDate] = useState(noteDate);

    // students note
    const [studentNotes] = useState([
        {
            default: "",
            label: "",
        },
        {
            default: "A (Excellent)",
            label: "4.00",
        },
        {
            default: "B (Très Bien)",
            label: "3.50",
        },
        {
            default: "C (Bien)",
            label: "3.00",
        },
        {
            default: "D (Assez bien)",
            label: "2.50",
        },
        {
            default: "E (Passable)",
            label: "2.00",
        },
        {
            default: "F (Insuffisant)",
            label: "1.00",
        },
        {
            default: "FX (Très insuffisant)",
            label: "0.00",
        },
        {
            default: "NE (Non-évalué)",
            label: "null",
        },
    ]);

    // Category State

    const [category] = useState([
        {
            default: "",
            label: "",
        },
        {
            default: "Contrôle Continu",
            label: "controle_continu",
        },
        {
            default: "Étude de Cas",
            label: "case_study",
        },
        {
            default: "Exercice",
            label: "exercise",
        },
        {
            default: "Projet",
            label: "project",
        },
        {
            default: "Projet de Groupe",
            label: "group_project",
        },
        {
            default: "Travail de Groupe",
            label: "group_work",
        },
        {
            default: "Test Final",
            label: "final_test",
        },
        {
            default: "Présentation Orale",
            label: "oral_presentation",
        },
        {
            default: "Participation",
            label: "participation",
        },
        {
          default: "GEMA LAB",
          label: "gema_lab",
        },
    ]);

    // Form State

    const [formState, inputHandler, setFormState] = useForm(
        {
            evalCategory: {
                value: "",
                isValid: false,
            },
            evalCoef: {
                value: "",
                isValid: true,
            },
        },
        false
    );

    // Fonction qui réarrange le tableaux des catégories

    let categoryName = [];
    for (const key in category) {
        const element = category[key];
        categoryName.push(element);
    }

    // Fonction qui vérifie si chaque étudiant est séléctionné ou non

    const checkStudents = () => {
        let isGood = false;
        students.forEach((student) => {
            if (student.isChecked === true) {
                isGood = true;
                // break;
            } else {
                setSubmitErr(true);
            }
        });
        return isGood;
    };

    const accountIdConstructor = async (array) => {
        let newAccountArray = [];

        Object.keys(array).forEach((element) => {
            newAccountArray.push(array[element].account_id);
        });
        return newAccountArray;
    };

    // Fonction qui va dupliquer le formState si il y a une note
    const noteExtractor = async (array) => {
        let newStudentNotes = {};

        for (const key of Object.keys(array)) {
            let id = array[key].account_id;
            newStudentNotes[id] = array[key];
            if (newStudentNotes[id].evaluation === "Non évalué") newStudentNotes[id].value = "null";
        }
        return newStudentNotes;
    };

    // Fonction qui permet de construire le formState

    const arrayConstructor = async (evaluationData, studentListData, studentNotes) => {
        // Reference values
        const evalCategoryValues = {
            "Contrôle Continu": "controle_continu",
            "Étude de Cas": "case_study",
            Exercice: "exercise",
            Projet: "project",
            "Projet de Groupe": "group_project",
            "Travail de Groupe": "group_work",
            "Test Final": "final_test",
            "Présentation Orale": "oral_presentation",
            Participation: "participation",
            "GEMA LAB": "gema_lab",
        };

        let notes;
        if (props.action === "update") {
            notes = await noteExtractor(studentNotes[0].links);
        }

        let newFormState;
        if (props.action === "update") {
            props.notes[0].criteria == "true" ? setIsChecked(true) : setIsChecked(false);
            newFormState = {
                evalCategory: {
                    value: evalCategoryValues[evaluationData[0].category] || "",
                    isValid: evalCategoryValues[evaluationData[0].category] ? true : false,
                },
                evalCoef: {
                    value: parseFloat(evaluationData[0].weight) || "1",
                    isValid: evaluationData[0].weight ? true : false,
                },
            };
        } else {
            newFormState = {
                evalCategory: {
                    value: "",
                    isValid: false,
                },
                evalCoef: {
                    value: "1",
                    isValid: false,
                },
            };
        }

        // Fonction qui permet de rajouter le formState "evalNote" et "evalComment" pour chaque student
        const newStudentArray = studentListData.map((student) => {
            const key = student.id;

            if (props.action === "update") {
                if (notes[key]) {
                    newFormState[key + "_evalNote"] = {
                        value: notes[key].value,
                        isValid: true,
                    };
                    newFormState[key + "_evalComment"] = {
                        value: notes[key].assessment,
                        isValid: true,
                    };
                } else {
                    newFormState[key + "_evalNote"] = {
                        value: "",
                        isValid: false,
                    };
                    newFormState[key + "_evalComment"] = {
                        value: "",
                        isValid: false,
                    };
                }
            } else {
                newFormState[key + "_evalNote"] = {
                    value: "",
                    isValid: false,
                };
                newFormState[key + "_evalComment"] = {
                    value: "",
                    isValid: false,
                };
            }

            return {
                ...student,
                isChecked: false,
            };
        });

        return { studentList: newStudentArray, newFormState: newFormState };
    };

    useEffect(() => {
        const fetchStudents = async () => {
            // Fetch for Students

            try {
                let reportsUrl;
                if (props.action === 'update'){
                    reportsUrl = `${process.env.REACT_APP_API_HOST}/evaluation/get-students-from-groups/${report[0].group_id}/${report[0].subject}?noteId=${props.notes[0].id}&teacherCurrentYear=${context.teacherCurrentAccountYear}`;

                } else reportsUrl = `${process.env.REACT_APP_API_HOST}/evaluation/get-students-from-groups/${report[0].group_id}/${report[0].subject}&teacherCurrentYear=${context.teacherCurrentAccountYear}`;

                const studentList = await sendRequest(reportsUrl, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                // Old version where we used to get Student list from REPORT
                // const accountIdData = await accountIdConstructor(studentLinks[0].links);

                // const studentListUrl = `${process.env.REACT_APP_API_HOST}/evaluation/account/v2?ids=${studentLinks}`;
                // const studentList = await sendRequest(studentListUrl, "GET", null, {
                //     Authorization: "Bearer " + auth.token,
                // });

                if (props.action === "update") {
                    const notesUrl = `${process.env.REACT_APP_API_HOST}/evaluation/get-note-by-report/${props.notes[0].id}`;
                    const studentNotes = await sendRequest(notesUrl, "GET", null, {
                        Authorization: "Bearer " + auth.token,
                    });

                    const data = await arrayConstructor(props.notes, studentList[0], studentNotes);

                    setFormState(data.newFormState);
                    setStudents(data.studentList);

                } else {
                    const data = await arrayConstructor(props.notes, studentList[0]);
                    
                    setFormState(data.newFormState);
                    setStudents(data.studentList);
                }

                setIsReady(true);
            } catch (err) {
                console.error(err);
            }
        };
        fetchStudents();
    }, []);

    // SUBMIT FCTN

    const submitEval = async (event) => {
        event.preventDefault();

    // Si aucun étudiant n'est sélectionné, on return rien

        const cestBon = await checkStudents();
        if (!cestBon) return;

    // Si aucune catégorie n'est sélectionnée, on return
    if (formState.inputs.evalCategory.value === "") return;

    // Si un étudiant ou plus est sélectionné, on continue

        let newStudentList;
        students.map((student) => {
            newStudentList = students.filter((student) => student.isChecked === true);
        });
        setStudents((newStudentList) => {
            // On map sur les students et pour chaque étudiant sélectionné, on rajoute deux lignes de formState, une pour la note, une pour le commentaire

            return newStudentList.map((student) => {
                return {
                    ...student,
                    note: formState.inputs[student.id + "_evalNote"].value,
                    comment: formState.inputs[student.id + "_evalComment"].value,
                };
            });
        });

        // Fonction qui permet de construire l'objet Link qui contient les étudiants notés

        const linksContructor = async () => {
            let links = {};
            students.map((student) => {
                if (
                    formState.inputs[student.id + "_evalNote"].value ||
                    formState.inputs[student.id + "_evalComment"].value
                ) {
                    student.isChecked = true;
                }
                if (student.isChecked == true) {
                    const key = student.id;
                    links[key] = {
                        value: isNaN(formState.inputs[student.id + "_evalNote"].value)
                            ? null
                            : parseFloat(formState.inputs[student.id + "_evalNote"].value),
                        assessment: formState.inputs[student.id + "_evalComment"].value,
                        evaluation:
                            formState.inputs[student.id + "_evalNote"].value === "null"
                                ? "Non évalué"
                                : null,
                    };
                }
            });
            return links;
        };

        // Construction des données du POST

        let data;
        if (props.action === "create" && report[0].group_id) {
            data = {
                place_id: parseInt(report[0].place_id, 10),
                teacher_id: parseInt(context.teacherVcard, 10),
                group_id: parseInt(report[0].group_id, 10),
                criteria: isChecked == true ? "true" : false,
                school_year: report[0].school_year,
                subject: report[0].subject,
                school_period: "Q1",
                level: formState.inputs.evalCategory.value,
                date: date.toISOString().split("T")[0],
                reference_value: 4,
                weight: parseFloat(formState.inputs.evalCoef.value, 10),
                links: await linksContructor(),
            };
        } else if (props.action === "create" && !report[0].group_id) {
            data = {
                place_id: parseInt(report[0].place_id, 10),
                teacher_id: parseInt(context.teacherVcard, 10),
                school_year: report[0].school_year,
                criteria: isChecked == true ? "true" : false,
                subject: report[0].subject,
                school_period: "Q1",
                level: formState.inputs.evalCategory.value,
                date: date.toISOString().split("T")[0],
                reference_value: 4,
                weight: parseFloat(formState.inputs.evalCoef.value, 10),
                links: await linksContructor(),
            };
        } else if (props.action === "update" && report[0].group_id) {
            data = {
                id: props.notes[0].id,
                place_id: parseInt(report[0].place_id, 10),
                teacher_id: parseInt(context.teacherVcard, 10),
                class: parseInt(report[0].group_id, 10),
                criteria: isChecked == true ? "true" : false,
                school_year: report[0].school_year,
                subject: report[0].subject,
                school_period: "Q1",
                level: formState.inputs.evalCategory.value,
                date: date.toISOString().split("T")[0],
                reference_value: 4,
                weight: parseFloat(formState.inputs.evalCoef.value, 10),
                links: await linksContructor(),
            };
        } else if (props.action === "update" && !report[0].group_id) {
            data = {
                id: props.notes[0].id,
                place_id: parseInt(report[0].place_id, 10),
                criteria: isChecked == true ? "true" : false,
                teacher_id: parseInt(context.teacherVcard, 10),
                school_year: report[0].school_year,
                subject: report[0].subject,
                school_period: "Q1",
                level: formState.inputs.evalCategory.value,
                date: date.toISOString().split("T")[0],
                reference_value: 4,
                weight: parseFloat(formState.inputs.evalCoef.value, 10),
                links: await linksContructor(),
            };
        }

        // POST pour une note

        let url;
        let response;
        if (props.action === "create") {
            url = `${process.env.REACT_APP_API_HOST}/evaluation/post-note`;
            try {
                response = await sendRequest(url, "POST", JSON.stringify(data), {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                });
                // if (!response.id) {
                //     setBddError(true);
                // }
            } catch (err) {
                console.error(err);
            }
            props.setNotesArray((prevState) => [
                ...prevState,
                {
                    id: response.id,
                    status: "current",
                    category: data.level,
                    date: data.date,
                    group_id: data.group_id,
                    observations: null,
                    criteria: data.criteria,
                    place_id: data.place_id,
                    reference_value: data.reference_value,
                    school_period: data.school_period,
                    school_year: data.school_year,
                    subject: data.subject,
                    teacher_id: data.teacher_id,
                    weight: data.weight,
                },
            ]);
        } else if (props.action === "update") {
            url = `${process.env.REACT_APP_API_HOST}/evaluation/patch-note/${props.notes[0].id}`;
            try {
                response = await sendRequest(url, "POST", JSON.stringify(data), {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                });
            } catch (err) {
                console.error(err);
            }
        }
        setOnPost(true);
    };

    // DELETE Function :

    const deleteNote = async () => {
        try {
            const deleteNoteUrl = `${process.env.REACT_APP_API_HOST}/evaluation/delete-note/${props.notes[0].id}`;
            const response = await sendRequest(deleteNoteUrl, "DELETE", null, {
                Authorization: "Bearer " + auth.token,
            });
            props.setNotesArray((prevState) =>
                prevState.filter((element) => element.id !== props.notes[0].id)
            );
            setOnDelete(true);
        } catch (err) {
            console.error(err);
        }
    };

    let closeBtn = (
        <ActionBtn
            id="close_evalModal"
            btnType="contained"
            btnStyle={styles.btnStyles}
            activeBtnStyle={styles.btn_active}
            btnText="Fermer la fenêtre"
            textStyle={styles.btn_text}
            onClick={props.modalFunction}
        />
    );


    return (
        <div className={styles.modalContainer}>
            <ModalHeader
                headerColor={styles.banner_color}
                headerTitle={report[0].subject_Name}
                closeBtn={props.modalFunction}
            />
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner textColor={styles.spinner_text_color} />
                </div>
            )}
            {(error || bddError) && (
                <HttpMessagePrompt
                    error={
                        bddError
                            ? "Une erreur a été détectée sur le serveur. Merci de réessayer ultèrieurement. Merci également de réactualiser votre page pour mettre à jour vos notes."
                            : "Une erreur a été détectée sur le serveur. Merci de réessayer ultèrieurement."
                    }
                    btn={closeBtn}
                />
            )}
            {okHttp && onPost && !isLoading && !bddError && (
                <HttpMessagePrompt
                    error={false}
                    btn={closeBtn}
                    message={"Votre note a bien été enregistrée."}
                />
            )}
            {okHttp && onDelete && !isLoading && (
                <HttpMessagePrompt
                    error={false}
                    btn={closeBtn}
                    message={"Votre note a bien été supprimée."}
                />
            )}
            {report && !onDelete && !onPost && students && !isLoading && (
                <>
                    <form onSubmit={submitEval} className={styles.form}>
                        <section className={styles.evalSection}>
                            {/* DATE */}

                            <div className={styles.selectStyles}>
                                <label htmlFor="date" className={styles.label}>
                                    Date de l'évaluation
                                </label>
                                <DatePicker
                                    id="evalDate"
                                    value={date}
                                    validators={[isRequired()]}
                                    locale="fr"
                                    className={styles.datePicker}
                                    dateFormat="yyyy/MM/dd"
                                    showTimeSelect
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                />
                            </div>

                            {/* CATEGORIES D'EVALUATION */}

                            {/* <div className={styles.selectStyles}> */}
                            {isReady && (
                                <Input
                                    onInput={inputHandler}
                                    label="Catégorie d'évaluation"
                                    type="text"
                                    name="evalCategory"
                                    id="evalCategory"
                                    validators={[isRequired()]}
                                    initialValue={formState.inputs.evalCategory.value}
                                    initialValid={formState.inputs.evalCategory.isValid}
                                    typeOfInput="select"
                                    content={categoryName}
                                    styles={styles.input}
                                    errorText={"Veuillez choisir une catégorie"}
                                />
                            )}
                            {/* </div> */}

                            {/* COEF */}

                            {/* <div className={styles.selectStyles}> */}
                            {isReady && (
                                <Input
                                    label="Coefficient"
                                    id="evalCoef"
                                    name="evalCoef"
                                    onInput={inputHandler}
                                    typeOfInput="input"
                                    type="number"
                                    styles={styles.input}
                                    validators={[isRequired()]}
                                    initialValue={formState.inputs.evalCoef.value}
                                    initialValid={formState.inputs.evalCoef.isValid}
                                    errorText={"Veuillez ajouter un coefficient."}
                                />
                            )}
                            {/* </div> */}
                            <div className={styles.assessment}>
                                <span>Commentaires visibles par les étudiants</span>
                                <CheckBox
                                    isChecked={isChecked}
                                    value={isChecked}
                                    onChange={() => {
                                        setIsChecked(!isChecked);
                                    }}
                                />
                            </div>

                            {/* STUDENT SECTION */}
                        </section>
                        <div className={styles.paramBar}>
                            <p className={styles.header_student}>Étudiant</p>
                            <p className={styles.header_note}>Note</p>
                            <p className={styles.header_comment}>Commentaire</p>
                        </div>
                        <section className={styles.studentDesktop}>
                            {students &&
                                isReady &&
                                students.map((student) => (
                                    <div key={student.id} className={styles.studentInfo}>
                                        <StudentEval
                                            id={student.id}
                                            name={student.name}
                                            rythme={student.rythme}
                                            avatarSrc={Avatar1}
                                            photo={student.photo}
                                            studentName={student.name}
                                            styles={styles.studentName}
                                            setStudents={setStudents}
                                            validators={[isRequired()]}
                                            commentFormValue={
                                                formState.inputs[student.id + "_evalComment"].value
                                            }
                                            commentFormValid={
                                                formState.inputs[student.id + "_evalComment"]
                                                    .isValid
                                            }
                                            noteFormValue={
                                                formState.inputs[student.id + "_evalNote"].value
                                            }
                                            noteFormValid={
                                                formState.inputs[student.id + "_evalNote"].isValid
                                            }
                                            inputHandler={inputHandler}
                                            content={studentNotes}
                                            setFormState={setFormState}
                                            formState={formState}
                                            isChecked={student.isChecked}
                                            studentNotes={studentNotes}
                                        />
                                    </div>
                                ))}
                        </section>
                        {submitErr && (
                            <span className={styles.spanErr}>
                                Veuillez selectionner au moins un élève et une catégorie
                                d'évaluation.
                            </span>
                        )}
                        {props.action === "create" && (
                            <ActionBtn
                                id="submitEval"
                                btnType="contained"
                                btnStyle={styles.btnStyles}
                                activeBtnStyle={styles.btn_active}
                                btnText="Enregistrer"
                                textStyle={styles.btn_text}
                                onClick={submitEval}
                            />
                        )}
                        {props.action === "update" && (
                            <div className={styles.modifyBtn}>
                                <ActionBtn
                                    id="deleteEval"
                                    btnType="outlined"
                                    btnStyle={styles.btnStylesDelete}
                                    activeBtnStyle={styles.btn_active_delete}
                                    btnText="Supprimer"
                                    textStyle={styles.btn_text_delete}
                                    onClick={deleteNote}
                                />
                                <ActionBtn
                                    id="updateEval"
                                    btnType="contained"
                                    btnStyle={styles.btnStylesModify}
                                    activeBtnStyle={styles.btn_active}
                                    btnText="Enregistrer"
                                    textStyle={styles.btn_text}
                                    onClick={submitEval}
                                />
                            </div>
                        )}
                    </form>
                </>
            )}
        </div>
    );
};

export default GenerateEvaluation;
