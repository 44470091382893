/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import sha256 from "crypto-js/sha256";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { useForm } from "../../hooks/form-hook";
import { isBlank, isRequired } from "../../utils/validators";
import { fixWindowPosition } from "../../utils/helperFunctions";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import Modal from "../../components/Modal/Modal";
import CheckBox from "../../components/CheckBox/CheckBox";
import ListHeader from "../../components/ListHeader/ListHeader";
import AbsencesList from "../../components/Tabs/Student/AbsencesList/AbsencesList";
import Input from "../../components/Input/Input";
import FilePicker from "../../components/FilePicker/FilePicker";
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Icons
import logOutIcon from "../../assets/images/leaveIcon.svg";

// Styles
import styles from "./StudentJustifyAbsences.module.scss";

const StudentJustifyAbsences = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // History context
    const history = useHistory();

    // Teacher context
    const { studentCampusId, studentName, studentLevel, studentSchool, studentCurrentPeriod, setStudentContext, unsetStudentContext } = useContext(StudentContext);

    // Backend Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Modal Window Status
    const [modalStatus, setModalStatus] = useState(false);

    // Fetched Student Absences
    const [studentAbsences, setStudentAbsences] = useState();

    // List of Ids to invoice
    const [idsList, setIdsList] = useState([]);

    const [isUnjustifiedChecked, setIsUnjustifiedChecked] = useState(false);

    // number max of char for justification
    const [nbMax, setNbMax] = useState(0);

        const selectOptions = [
        { default: "", label: ""},
        { default: "1. Je souhaite transmettre un document justificatif pour les motifs suivants (décès, maladie, rdv d'administration)", label: "document" },
        { default: "2. Je souhaite expliquer les motifs de mon absence sans apport d’un document justificatif", label: "comment" },
        { default: "3. Je ne souhaite pas justifier mon absence", label: "unjustified" },
    ];

    const documentSelectOptions = [
        { default: "", label: ""},
        { default: "Décès → Joindre un justificatif d’acte de décès", label: "death" },
        { default: "Maladie → joindre un justificatif (certificat médical ou arrêt de travail)", label: "medical" },
        { default: "RDV auprès d’une administration → joindre un justificatif (convocation, justification de présence)", label: "appointment" },
    ];

    const commentSelectOptions = [
        { default: "", label: ""},
        { default: "Présence en entreprise", label: "enterprise" },
        { default: "Problème de santé", label: "medical" },
        { default: "Autre ", label: "other" },
    ];

       // Form State
    const [formState, inputHandler, setFormState] = useForm(
        {
            absenceChoices: {
                value: "",
                isValid: false,
            },
            documentChoices: {
                value: "",
                isValid: false,
            },
            commentChoices: {
                value: "",
                isValid: false,
            },
            support_comment: {
                value: "",
                isValid: true,
            },
            file: {
                value: undefined,
                isValid: false,
            },
        },
        false
    );

    // Justification Type
    const [justificationType, setJustificationType] = useState("both");

    // End of actions state
    const [noFurtherAction, setNofurtherAction] = useState(false);

    // Fetch Absences
    useEffect(() => {
        const fetchAbsences = async () => {
            try {
                const url = `${process.env.REACT_APP_API_HOST}/planning/absences/${studentCurrentPeriod}`;
                const absences = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                // Filter and set absences to justify
                setStudentAbsences(absences.filter(({ reason }) => reason === "to_justify"));
            } catch (err) {
                console.error(err);
            }
        };
        if (studentCurrentPeriod) fetchAbsences();
    }, [studentCurrentPeriod]);

    useEffect(() => {
        if (formState.inputs.support_comment.value.length >= 1) setJustificationType("comment");
        else if (formState.inputs.file.isValid) setJustificationType("file");
        else setJustificationType("both");
    }, [formState]);

    const confirmationModalHandler = () => {
        fixWindowPosition(modalStatus);
        setModalStatus(!modalStatus);
    };

    const fetchContextHandler = async () => {
        try {
            const url = `${process.env.REACT_APP_API_HOST}/login/reload-context`;
            const loginData = await sendRequest(url, "GET", null, {
                Authorization: "Bearer " + auth.token,
            });

            if (loginData.status === "blocked") {
                unsetStudentContext();
                auth.logout();
            }

            // Pass data to the login Auth Hook
            auth.login(
                loginData.token,
                loginData.expires,
                loginData.role,
                loginData.type,
                loginData.situation,
                loginData.backToken
            );

            // Pass data to student context Hook
            setStudentContext(
                loginData.status,
                loginData.restricted,
                loginData.campus,
                loginData.accademicPeriod,
                loginData.profile_photo,
                loginData.name,
                loginData.finStatus,
                loginData.accademicLevel,
                loginData.school,
                loginData.attestation,
                loginData.certificate,
                loginData.nextYearLevel,
                loginData.nextYearCampus,
                loginData.rythme,
                loginData.offresRestriction,
                loginData.notificationOfferHome
            );
            history.push("/");
        } catch (err) {
            console.error(err);
        }
    };

    const postJustificationHandler = async () => {

        // Prevent form if not completed
        if (idsList.length === 0) return;
        if ((formState.inputs.documentChoices.value != '' && !formState.inputs.file.isValid) || (formState.inputs.commentChoices.value != '' && formState.inputs.support_comment.value.length === 0)) return;

        // Construct Formdata
        const formData = new FormData();
        formData.append("idsList", idsList);
        formData.append("name", studentName);
        formData.append("school", studentSchool);
        formData.append("campus", studentCampusId);
        formData.append("accademicLevel", studentLevel);

        // Check justification type
        if (justificationType === "file") {
            const checksum_hash = sha256(formState.inputs.file.value.size.toString + formState.inputs.file.value.name).toString();
            formData.append("file", formState.inputs.file.value);
            formData.append("checksum_hash", checksum_hash);
            formData.append("justificationType", formState.inputs.documentChoices.value);
        } else if (justificationType === 'comment') {
            formData.append("comment", formState.inputs.support_comment.value);
            formData.append("justificationType", formState.inputs.commentChoices.value);
        } else formData.append("justificationType", "unjustified")

        // API Call
        try {
            const url = `${process.env.REACT_APP_API_HOST}/planning/justify-absences`;
            const res = await sendRequest(url, "POST", formData, {
                Authorization: "Bearer " + auth.token,
            });

            // Filter Absences State
            setStudentAbsences((prevAbsences) => {
                let filteredAbsences = [];
                prevAbsences.forEach((absence) => { if (!idsList.includes(absence.id)) filteredAbsences.push(absence) });
                if (filteredAbsences.length === 0) setNofurtherAction(true);
                return filteredAbsences;
            });

            // Reset FormState
            setFormState(
                {
                    absenceChoices: {
                        value: "",
                        isValid: false,
                    },
                    documentChoices: {
                        value: "",
                        isValid: false,
                    },
                    commentChoices: {
                        value: "",
                        isValid: "",    
                    },
                    support_comment: {
                        value: "",
                        isValid: false,
                    },
                    file: {
                        value: "",
                        isValid: false,
                    },
                },
                false
            );

            // Reset IdsList && Justification Type
            setIdsList([]);
            setJustificationType("both");

            // Open modal confirmation message
            confirmationModalHandler();
        } catch (err) {
            console.error(err);
        }
    };

    const checkAbsencesHandler = (event, isChecked) => {
        const checkboxId = Number(event.currentTarget.id);

        // Push new id to ids list
        if (isChecked) setIdsList((prevIds) => [...prevIds, checkboxId]);
        else if (!isChecked && idsList.length !== 0)
            setIdsList((prevIds) => {
                
                // Remove unchecked selection from ids list
                const newArray = [...prevIds];
                newArray.splice(prevIds.indexOf(checkboxId), 1);
                return newArray;
            });
        else setIdsList([]);
    };

    return (
        <>
            <Modal
                show={modalStatus}
                onClickFunction={confirmationModalHandler}
                overlay={
                    <HttpMessagePrompt
                        error={false}
                        message="Votre justificatif a été bien envoyé."
                        secondaryMessage="Cliquez sur le bouton pour continuer."
                        btn={
                            <ActionBtn
                                id="invoiceList"
                                btnType="contained"
                                btnStyle={styles.btnStyles}
                                activeBtnStyle={styles.btn_active}
                                btnText="Retourner aux absences"
                                textStyle={styles.btn_text}
                                onClick={confirmationModalHandler}
                            />
                        }
                    />
                }
            />
            {!isLoading && (
                <div className={styles.logoutWrapper}>
                    <ActionBtn
                        id="test"
                        btnType="contained"
                        activeBtnStyle={styles.logout_btn}
                        btnText="Me déconnecter"
                        icon={logOutIcon}
                        iconColor={styles.whiteIcon}
                        textStyle={styles.btn_text}
                        onClick={() => {
                            unsetStudentContext();
                            auth.logout();
                        }}
                    />
                </div>
            )}
            <main className="tab_container">
                {error && (
                    <div className="error_wrapper">
                        <HttpMessagePrompt error={error} />
                    </div>
                )}
                {isLoading && (
                    <div className="spinner">
                        <LoadingSpinner />
                    </div>
                )}
                {!isLoading && noFurtherAction && !error && (
                    <div className={styles.subheader_wrapper}>
                        <HttpMessagePrompt
                            error={false}
                            message="Vous n'avez plus d'absences à justifier"
                        />
                        <ActionBtn
                            id="homeBtn"
                            btnType="contained"
                            btnStyle={styles.btnStyles}
                            activeBtnStyle={styles.btn_active}
                            btnText="Retourner à l'accueil"
                            textStyle={styles.btn_text}
                            onClick={fetchContextHandler}
                        />
                    </div>
                )}
                {!isLoading && studentAbsences && !noFurtherAction && !error && (
                    <div className={styles.subheader_wrapper}>
                        <ListHeader
                            title="Justifiez vos absences"
                            message={
                                <span className={styles.description}>
                                    Pour justifier vos absences, sélectionnez la ou les absences et
                                    joignez votre arrêt maladie, attestation de votre entreprise ou
                                    autre document. Si vous n’avez pas de document officiel, merci
                                    de saisir un court commentaire expliquant la raison de votre
                                    absence dans le champ prévu à cet effet. Chaque justificatif
                                    sera étudié par le Pôle Pédagogique !
                                </span>
                            }
                        />
                        <AbsencesList absences={studentAbsences} onClick={checkAbsencesHandler} justifyAbsence={noFurtherAction} />
                        <p className={styles.comment}>* Formats acceptés .pdf, .jpeg ou .jpg.</p>
                        <div className={styles.upload_wrapper}>
                            {idsList.length == 0 && (
                            <span className={styles.spanIdList0}>
                                Veuillez sélectionner un ou plusieurs créneaux d'absence.
                            </span>
                            )}
                                <Input
                                    id="absenceChoices"
                                    name="absenceChoices"
                                    type="text"
                                    typeOfInput="select"
                                    label="Veuillez choisir une des propositions ci-dessous"
                                    styles={styles.inputSelect}
                                    validators={[isRequired()]}
                                    errorText="Veuillez choisir une proposition"
                                    initialValue=""
                                    initialValid={false}
                                    content={selectOptions}
                                    onInput={inputHandler}
                                    disabled={(formState.inputs.absenceChoices.value != '' || idsList.length == 0) ? true : false}
                                />
                                {formState.inputs.absenceChoices.value == 'document' && (
                                    <Input
                                         id="documentChoices"
                                         name="documentChoices"
                                         type="text"
                                         typeOfInput="select"
                                         label="Veuillez choisir une des propositions ci-dessous"
                                         styles={styles.inputSelect}
                                         validators={[isRequired()]}
                                         errorText="Veuillez choisir une proposition"
                                         initialValue=""
                                         initialValid={false}
                                         content={documentSelectOptions}
                                         onInput={inputHandler}
                                         disabled={(formState.inputs.documentChoices.value != '' || idsList.length == 0) ? true : false}
                                         />
                                )}
                                {formState.inputs.absenceChoices.value == 'comment' && (
                                    <Input
                                        id="commentChoices"
                                        name="commentChoices"
                                        type="text"
                                        typeOfInput="select"
                                        label="Veuillez choisir une des propositions ci-dessous"
                                        styles={styles.inputSelect}
                                        validators={[isRequired()]}
                                        errorText="Veuillez choisir une proposition"
                                        initialValue=""
                                        initialValid={false}
                                        content={commentSelectOptions}
                                        onInput={inputHandler}
                                        disabled={(formState.inputs.commentChoices.value != '' || idsList.length == 0) ? true : false}
                                        />
                                )}
                            <div className={styles.inputs_wrapper}>
                            {formState.inputs.documentChoices.value != '' && (
                            <FilePicker
                                mimeTypes=".jpg, .jpeg, .pdf"
                                inputHandler={inputHandler}
                                formState={formState}
                                formStateFileKey={"file"}
                                disabled={justificationType === "comment" ? true : false}
                            />
                            )}
                            {formState.inputs.commentChoices.value != '' && (
                                <div className={styles.comment_wrapper}>
                                    <Input
                                        id="support_comment"
                                        name="support_comment"
                                        type="text"
                                        maxLength="250"
                                        typeOfInput="textArea"
                                        rows="5"
                                        label="Votre justification"
                                        placeholder="Veuillez expliquer votre absence ici"
                                        validators={[isBlank()]}
                                        initialValue=""
                                        initialValid={formState.inputs.support_comment.isValid}
                                        onInput={inputHandler}
                                        disabled={justificationType === "file" ? true : false}
                                        onCountFctn={e => setNbMax(e.target.value.length)}
                                    />
                                    <p className={styles.nbMax}>{nbMax}/250</p>
                                </div>
                            )}
                            {formState.inputs.absenceChoices.value == 'unjustified' && (
                                <div className={styles.unjustifiedCheck}>
                                    <CheckBox
                                        id={"unjustified"}
                                        key={"unjustified"}
                                        isChecked={isUnjustifiedChecked}
                                        onChange={() => {
                                            setIsUnjustifiedChecked(!isUnjustifiedChecked);
                                        }}
                                    />
                                    <p className={styles.unjustifiedP}>Attention, en validant ce choix, vous ne pourrez plus justifier cette absence.</p>
                                </div>
                            )}
                            </div>
                            <div className={styles.btn_wrapper}>
                                <ActionBtn
                                    id="invoiceList"
                                    btnType="contained"
                                    btnStyle={styles.btnStyles}
                                    activeBtnStyle={
                                        formState.inputs.absenceChoices.value != ''
                                        ? styles.btn_activeCancel
                                        : styles.btn_disabled
                                    }
                                    btnText="Annuler mon choix"
                                    textStyle={styles.btn_text}
                                    disabled={
                                        formState.inputs.absenceChoices.value != ''
                                        ? false
                                        : true
                                    }
                                    onClick={() => {
                                        setIsUnjustifiedChecked(false);
                                        setFormState(
                                            {
                                                absenceChoices: {
                                                    value: "",
                                                    isValid: false,
                                                },
                                                documentChoices: {
                                                    value: "",
                                                    isValid: false,
                                                },
                                                commentChoices: {
                                                    value: "",
                                                    isValid: "",    
                                                },
                                                support_comment: {
                                                    value: "",
                                                    isValid: false,
                                                },
                                                file: {
                                                    value: "",
                                                    isValid: false,
                                                },
                                            },
                                            false
                                        );
                                    }}
                                />
                                <ActionBtn
                                    id="invoiceList"
                                    btnType="contained"
                                    btnStyle={styles.btnStyles}
                                    activeBtnStyle={
                                        idsList.length !== 0 &&
                                        ((formState.inputs.documentChoices.value != '' && formState.inputs.file.isValid) ||
                                            (formState.inputs.commentChoices.value != '' && formState.inputs.support_comment.value.length >= 1) ||
                                            (formState.inputs.absenceChoices.value == 'unjustified' && isUnjustifiedChecked))
                                            ? styles.btn_active
                                            : styles.btn_disabled
                                    }
                                    btnText="Envoyer le justificatif"
                                    textStyle={styles.btn_text}
                                    disabled={
                                        idsList.length !== 0 &&
                                        ((formState.inputs.documentChoices.value != '' && formState.inputs.file.isValid) ||
                                            (formState.inputs.commentChoices.value != '' && formState.inputs.support_comment.value.length >= 1) ||
                                            (formState.inputs.absenceChoices.value == 'unjustified' && isUnjustifiedChecked))
                                            ? false
                                            : true
                                    }
                                    onClick={postJustificationHandler}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
};

export default StudentJustifyAbsences;
