/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";
import { StudentContext } from "../../../../../context/student-context";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";
import { fixWindowPosition, planningConstructor } from "../../../../../utils/helperFunctions";

// Calendar Imports
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import previousMonday from "date-fns/previousMonday";
import nextSunday from "date-fns/nextSunday";
import getDay from "date-fns/getDay";
import fr from "date-fns/locale/fr";

import "react-big-calendar/lib/css/react-big-calendar.css";

// Components
import Modal from "../../../../Modal/Modal";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import TeacherCalendarLeyend from "../CalendarLeyend/CalendarLeyend";
import AttendanceList from "../AttendanceList/AttendanceList";

// Styles
import styles from "./PlanningCalendar.module.scss";
import "./agenda.scss";
import "./event.scss";
import "./month.scss";
import "./reset.scss";
import "./time-column.scss";
import "./time-grid.scss";
import "./styles.scss";
import "./toolbar.scss";
import "./variables.scss";
import DetailPlanning from "../DetailPlanning/DetailPlanning";

const locales = { fr };
const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });

const PlanningCalendar = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(auth.userType === "student" ? StudentContext : TeacherContext);

    // Backend Request Hook
    const { isLoading, sendRequest } = useHttpRequest();

    // Window Size Hook
    const { width } = useWindowDimensions();

    // Calendar Events
    const [events, setEvents] = useState();

    // Today Date
    const [today] = useState(new Date());

    // Picked Calendar Event
    const [calendarSlotData, setCalendarSlotData] = useState();

    // Modal Window Status
    const [modalStatus, setModalStatus] = useState(false);

    // Actual Modal View
    const [modalView, setModalView] = useState();

    // Fetch URLs
    const [fetchUrls] = useState({
        student: `${process.env.REACT_APP_API_STUDENT}/planning/calendar?`,
        teacher: `${process.env.REACT_APP_API_TEACHER}/planning/calendar?account_id=${context.teacherSelectedAccount}`,
    });

    // Fetch Calendar Events
    useEffect(() => {
        const fetchCalendarEvents = async () => {

            // Calculate three week span of data
            // let beginDate = previousMonday(previousMonday(today)).toISOString().split("T")[0];
            // let endDate = nextSunday(nextSunday(today)).toISOString().split("T")[0];

            const year = (auth.userType === "student") ? context.studentCurrentPeriod.replaceAll("d", "").split("-") : context.teacherCurrentAccountYear.replaceAll("d", "").split("-");
            
            let beginDate = `${year[0]}-09-01`;
            let endDate = `${year[1]}-12-29`;

            // Fetch entire calendar if admin
            if (auth.userRole === "admin") {
                beginDate = `${year[0]}-09-01`;
                endDate = `${year[1]}-12-29`;
            }
            
            try {
                const url = ((auth.userType === "student") ? fetchUrls.student : fetchUrls.teacher) + `&begin=${beginDate}&end=${endDate}`;
                const events = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setEvents({
                    fetchedSpan: {
                        futureDate: endDate,
                        pastDate: beginDate,
                    },
                    planning: await planningConstructor(auth.userType, events, styles),
                });
            } catch (err) {
                console.error(err);
            }
        };
        fetchCalendarEvents();
    }, []);

    // Pass event slot (start date, end date) to the function to read the property and create the proper style of the slot
    const eventBlockStyles = (event, start, end, isSelected) => {
        let style = {
            borderRadius: "0px",
            fontSize: "1.4rem",
            fontWeight: "500",
            color: "white",
            border: "none",
            backgroundColor: "#F3F2ED",
            boxShadow: "3px 3px 7px #bebebe87",
        };

        if (auth.userType === "student") {
            if (event.status == "scheduled") {
                if (event.end < today) style.borderTop = "3px solid #00b61d";
                else style.borderTop = "3px solid #1a2ca9";
                style.cursor = "copy";

            }
            else if (event.status == "completed") {
                if (event.end < today) style.borderTop = "3px solid #00b61d";
                else style.borderTop = "3px solid #1a2ca9";
                style.cursor = "copy";

            }
            else if (event.status == "canceled") {
                style.borderTop = "3px solid #757575";
                style.cursor = "not-allowed";
            } else {
                style.borderTop = "3px solid #00b61d";
                style.cursor = "copy";
            }
        } else {
            switch (event.status) {
                case "scheduled":
                    style.backgroundColor = event.end < today ? "#f9ac00" : "#1a2ca9";
                    style.cursor = "copy";
                    break;

                case "realized":
                    style.backgroundColor = "#1ebba5";
                    style.cursor = "default";
                    break;

                case "to_invoice":
                    style.backgroundColor = "#1ebba5";
                    style.cursor = "default";
                    break;

                case "invoiced":
                    style.backgroundColor = "#00b61d";
                    style.cursor = "default";
                    break;

                case "validate":
                    style.backgroundColor = "#00b61d";
                    style.cursor = "default";
                    break;

                case "canceled":
                    style.backgroundColor = "#db3e00";
                    style.cursor = "not-allowed";
                    break;

                case "replaced":
                    style.backgroundColor = "#db3e00";
                    style.cursor = "default";
                    break;

                default:
                    style.backgroundColor = "#f9ac00";
                    break;
            }
        }

        return { style };
    };

    // const onSlotChange = (slotInfo) => {
    //     console.log("onSlotChange", slotInfo) // Shows the event details provided while booking
    // }

    const onNavigationHandler = async (date, view) => {
        let beginDate = "",
            endDate = "",
            futureUpdatedDate = null,
            pastUpdatedDate = null,
            calDate = date.toISOString().split("T")[0],
            calWeekDay = date.toString().split(" ")[0],
            calSpan = events.fetchedSpan;

        switch (view) {
            case "day":
                if (calDate === today) return;
                else if (calDate > today.toISOString().split("T")[0]) {
                    if (calDate > calSpan.futureDate) {
                        if (calWeekDay == "Mon") {
                            beginDate = calDate;
                            endDate = futureUpdatedDate = nextSunday(date)
                                .toISOString()
                                .split("T")[0];
                        } else return;
                    } else return;
                } else {
                    if (calDate < calSpan.pastDate) {
                        if (calWeekDay == "Sun") {
                            beginDate = pastUpdatedDate = previousMonday(date)
                                .toISOString()
                                .split("T")[0];
                            endDate = calDate;
                        } else return;
                    } else return;
                }

                break;

            case "week":
                if (calDate === today) return;
                else if (calDate > today.toISOString().split("T")[0]) {
                    if (calDate > calSpan.futureDate) {
                        beginDate = previousMonday(date).toISOString().split("T")[0];
                        endDate = futureUpdatedDate = nextSunday(date).toISOString().split("T")[0];
                    } else return;
                } else {
                    if (calDate < calSpan.pastDate) {
                        beginDate = pastUpdatedDate = previousMonday(date)
                            .toISOString()
                            .split("T")[0];
                        endDate = nextSunday(date).toISOString().split("T")[0];
                    } else return;
                }

                break;

            case "month":
                if (calDate === today) return;
                else if (calDate > today.toISOString().split("T")[0]) {
                    if (calDate > calSpan.futureDate) {
                        beginDate = previousMonday(date).toISOString().split("T")[0];
                        endDate = nextSunday(date).toISOString().split("T")[0];
                    } else return;
                } else {
                    if (calDate < calSpan.pastDate) {
                        beginDate = previousMonday(date).toISOString().split("T")[0];
                        endDate = nextSunday(date).toISOString().split("T")[0];
                    } else return;
                }
                break;

            default:
                return;
        }

        try {

            // Fetch New Events
            const url = ((auth.userType === "student") ? fetchUrls.student : fetchUrls.teacher) + `&begin=${beginDate}&end=${endDate}`;

            // Turn ON loading spinner
            document.getElementById("loadingScreen").style.display = "flex";

            const events = await fetch(url, {
                method: "GET",
                headers: { Authorization: "Bearer " + auth.token },
                credentials: 'include',
            }).then((res) => res.json());

            const newStructuredEvents = await planningConstructor(auth.userType, events, styles);

            // Update Course State
            if (newStructuredEvents.length > 0) {
                setEvents((prevEvents) => ({
                    fetchedSpan: {
                        futureDate: futureUpdatedDate ? futureUpdatedDate : prevEvents.fetchedSpan.futureDate,
                        pastDate: pastUpdatedDate ? pastUpdatedDate : prevEvents.fetchedSpan.pastDate,
                    },
                    planning: [...prevEvents.planning, newStructuredEvents[0]],
                }));
            } else {
                setEvents((prevEvents) => ({
                    ...prevEvents,
                    fetchedSpan: {
                        futureDate: futureUpdatedDate ? futureUpdatedDate : prevEvents.fetchedSpan.futureDate,
                        pastDate: pastUpdatedDate ? pastUpdatedDate : prevEvents.fetchedSpan.pastDate,
                    },
                }));
            }

            // Turn OFF loading spinner
            document.getElementById("loadingScreen").style.display = "none";

        } catch (err) {
            console.error(err);
        }
    };

    const attendanceListModalHandler = (event) => {
        if (modalStatus === false) {
            if (event.status !== "scheduled") {
                if (auth.userRole !== "admin") return;
            }
            fixWindowPosition(modalStatus);
            setModalView("attendanceList");
            setCalendarSlotData(event);
            setModalStatus(true);
        } else {
            fixWindowPosition(modalStatus);
            setCalendarSlotData();
            setModalStatus(false);
        }
    };

    const detailPlanningModalHandler = (event) => {
        if (modalStatus === false) {
            if (event.status !== "scheduled") {
                if (auth.userRole !== "admin") return;
            }
            fixWindowPosition(modalStatus);
            setModalView("detailPlanning");
            setCalendarSlotData(event);
            setModalStatus(true);
        } else {
            fixWindowPosition(modalStatus);
            setCalendarSlotData();
            setModalStatus(false);
        }
    };

    const modalList = {
        attendanceList: (
            <AttendanceList
                data={calendarSlotData}
                modalFunction={attendanceListModalHandler}
                updateEvents={setEvents}
            />
        ),
        detailPlanning: (
            <DetailPlanning
                data={calendarSlotData}
                modalFunction={detailPlanningModalHandler}
                updateEvents={setEvents}
            />
        ),
    };

        return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <Modal
                        show={modalStatus}
                        onClickFunction={attendanceListModalHandler}
                        overlay={calendarSlotData && modalList[modalView]}
                    />
                    <div style={{ position: "relative" }}>
                        <div id="loadingScreen" className={styles.loading_bg}>
                            <LoadingSpinner textColor={styles.spinner_text} />
                        </div>
                        <div className={styles.container}>
                            {!isLoading && events && (
                                <Calendar
                                    className={styles.calendar}
                                    views={[ "week", "day", "agenda"]}
                                    localizer={localizer} // dates localizer
                                    // step={60} // division of the calendar (60 min step)
                                    events={events.planning} // events array
                                    startAccessor="start"
                                    endAccessor="end"
                                    defaultView={width <= 767 ? "day" : "week"} // views : month, week, day, agenda
                                    // selectable={true} // allows creating an event entry by selecting a slot in the calendar
                                    /* =================================================================== */
                                    // ICI reactiver modal
                                    onSelectEvent={(event) => {
                                        if (auth.userType === "teacher") return attendanceListModalHandler(event);
                                        if (auth.userType === "student") return detailPlanningModalHandler(event);
                                        else return;
                                    }} // Captures the click event on a created calendar slot
                                    /* =================================================================== */
                                    // onSelectSlot={(slotInfo) => onSlotChange(slotInfo)} // Captures the create event on the calendar slot
                                    eventPropGetter={eventBlockStyles} // styles for the calendar slot
                                    // style={{ height: "60rem" }} // general styles of the calendar
                                    min={new Date(2019, 0, 1, 8, 0)} // starting hour range for week view
                                    max={new Date(2030, 0, 1, 22, 0)} // ending hour range for week view
                                    culture="fr"
                                    messages={{
                                        // custom menu button labels
                                        next: ">>",
                                        previous: "<<",
                                        today: "Ajourd'hui",
                                        month: "Mois",
                                        week: "Semaine",
                                        day: "Jour",
                                    }}
                                    onNavigate={onNavigationHandler} // returns the current calendar view and the current date +-1 day/week/month
                                />
                            )}
                            <TeacherCalendarLeyend user={auth.userType} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PlanningCalendar;
